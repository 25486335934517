document.addEventListener("DOMContentLoaded", function(event): void {
    const hamburger = document.querySelector('.hamburger');
    const navContainer = document.querySelector('.navigation-container');
    const navLinks = document.querySelectorAll('.navigation__nav-li');
    hamburger.addEventListener('click', (): void => {
        navContainer.classList.toggle('active');
    })
    navLinks.forEach(link => {
        link.addEventListener('click', (): void => {
            navContainer.classList.remove('active');
        })
    })
});